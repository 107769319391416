/** @file
 * Archivo de marcas de amplitud para workflow
 */
import onmount from 'onmount';

// Acción rápida "Otras solicitudes" desde el Portal del Colaborador
// Botón "Crear Solicitud" desde el index de solicitudes
onmount('.start-wf-amplitud', async function () {
  $(this).on('click', function () {
    const element = $(this);
    const importData = {
      'source': element.attr('data-source'),
    };
    sendAmplitudeTracking(element, 'Start_Request_Workflow', importData);
  });
});

// Seleccionar plantilla en el modal de solicitudes
onmount('[data-amplitude="Select_Template_Request_Workflow"]', async function () {
  $(this).on('click', function () {
    const element = $(this);

    // Get the current path
    const currentPath = window.location.pathname;

    // Determine the source based on the path
    const source = currentPath.includes('/portal') ? 'Portal' : 'Workflow';

    const importData = {
      'source': source,
      'template_title': element.data('template-title'),
      'template_type': element.data('template-type'),
      'templates_count': element.data('templates-count'),
      'workflow_actions': element.data('workflow-actions'),
    };
    sendAmplitudeTracking(element, 'Select_Template_Request_Workflow', importData);
  });
});

// Botón "Solicitar" o "aprobar" dentro de un formulario de Workflow
// Al crear un proceso de Onboarding
onmount('.submit-wf-amplitud', async function () {
  $(this).on('click', function () {
    const element = $(this);
    const importData = {
      'source': element.attr('data-source'),
      'template_id': element.attr('data-template-id'),
      'template_type': element.attr('data-template-type'),
      'template_name': element.attr('data-template-name'),
      'process_id': element.attr('data-process-id'),
      'operation_name': element.attr('data-operation-name'),
      'total_operations': element.attr('data-total-operations'),
      'actual_operation': element.attr('data-actual-operation'),
      'workflow_actions': element.attr('data-workflow-actions'),
      'automatic_notifications_enabled': element.attr('data-automatic-notifications-enabled'),
      'is_open_aswer_enabled': element.attr('data-is-open-aswer-enabled'),
      'workflow_template_status': element.attr('data-workflow-template-status'),
      'operation_id': element.attr('data-operation-id'),
      'operation_permission_type': element.attr('data-operation-permission-type'),
      'operation_download_report_by_approvers_enable':
        element.attr('data-operation-download-report-by-approvers-enable'),
      'operation_approve_by_hierarchy_enable': element.attr('data-operation-approve-by-hierarchy-enable'),
      'operation_limit_areas_enable': element.attr('data-operation-limit-areas-enable'),
    };
    sendAmplitudeTracking(element, 'Request_Workflow', importData);
  });
});

// Botón "crear plantilla" en workflow/config
onmount('#start-create-template-workflow', async function () {
  $(this).on('click', function () {
    sendAmplitudeTracking($(this), 'Start_Create_Template_Workflow', {});
  });
});

// Dar crear a una plantilla de solicitudes
onmount('#create-template-workflow', async function () {
  $(this).on('click', function () {
    const element = $(this);
    const selectElement = $('#process_template_type').find(':selected');
    const nameElement = $('#process_template_title');
    const importData = {
      'template_type': selectElement.html(),
      'template_name': nameElement.val(),
    };
    if(selectElement.val() && nameElement.val()) {
      sendAmplitudeTracking(element, 'Create_Template_Workflow', importData);
    }
  });
});

// Botón Guardar en una plantilla
onmount('#save-process-template', async function () {
  $(this).on('click', function () {
    const element = $(this);
    const importData = {
      'source': element.attr('data-source'),
      'template_name': $('#process_template_title').val(),
      'template_id': element.attr('data-template-id'),
      'template_type': element.attr('data-template-type'),
      'template_status': element.attr('data-template-status'),
      'total_operations': $('[id*="tab-operation-"]').length,
      'total_notifications': $('.workflow-notification-form-container').length,
      'automatic_notifications_enabled': $('#process_template_automatic_notifications').is(':checked'),
      'is_open_aswer_enabled': $('#process_template_show_processes_started_by_other_user').is(':checked'),
      'total_actions': Math.max(0, $('[id^="actions_"] tr').length - 1), // Tabla de acciones, restar 1 para eliminar el header
      'workflow_actions': $('[id^="actions_"] tr').map(function () {
        return $(this).find('td[data-table-attribute="Acción"]').text().trim();
      }).get().filter(Boolean)[0] || null,
    };

    sendAmplitudeTracking(element, 'Save_Template_Workflow', importData);
  });
});

// Botón "Previsualizar" en una plantilla
onmount('.preview-workflow-form', async function () {
  $(this).on('click', function () {
    const element = $(this);
    const importData = {
      'source': element.attr('data-source'),
      'template_id': element.attr('data-template-id'),
      'template_type': element.attr('data-template-type'),
      'template_name': element.attr('data-template-name'),
      'operation_id': element.attr('data-operation-id'),
      'operation_name': element.attr('data-operation-name'),
      'operation_fields': element.attr('data-operation-fields'),
    };
    sendAmplitudeTracking(element, 'Preview_Workflow_Form', importData);
  });
});

function sendAmplitudeTracking(element, message, data) {
  element.trigger('amplitudeTracking', {
    message: message,
    data: data,
  });
}
