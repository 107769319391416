/**
 * @file Este archivo contiene la lógica para validar formularios.
 * Desactiva la validación nativa del navegador y aplica validación personalizada.
 * Usa onmount para manejar eventos de manera eficiente.
 */

import onmount from 'onmount';

let previousRequiredInputs = [];
const activeListeners = new Map();

// Registra el comportamiento de validación para los formularios con clase '.simple_form'
onmount('.simple_form', function () {
  const form = this; // 'this' es el elemento DOM seleccionado por onmount
  if (hasClientSideValidation(form)) return;

  applyFormValidation(form);

  // Es necesario cuando se cambia una propiedad de algún elemento del DOM
  // Por ejemplo quitar la clase `required` con el behvior toggle-required.js
  form.addEventListener('change', () => {
    applyFormValidation(form);
  });
});

/**
 * Verifica si el formulario ya tiene empleado una validación desde el cliente.
 * @param {HTMLFormElement} form - El formulario que se validará.
 * @returns {boolean} - Retorna true si encuentra el data-atributo.
 */
function hasClientSideValidation(form) {
  return form.hasAttribute('data-client-side-validations');
}

/**
* Aplica validaciones personalizadas a un formulario.
* @param {HTMLFormElement} form - El formulario al que se aplicarán las validaciones.
*/
function applyFormValidation(form) {
  disableNativeValidation(form);

  const requiredInputs = getVisibleRequiredInputs(form);

  addRealTimeValidation(requiredInputs);

  form.addEventListener('submit', (event) => {
    const requiredInputsSubmit = getVisibleRequiredInputs(form);

    handleFormSubmit(event, requiredInputsSubmit);
  }, true);
}

/**
 * Desactiva la validación nativa del navegador en un formulario.
 * @param {HTMLFormElement} form - El formulario al que se desactivará la validación nativa.
 */
function disableNativeValidation(form) {
  form.setAttribute('novalidate', 'novalidate');
  form.setAttribute('data-patch', 'true');
}

/**
 * Obtiene los campos requeridos visibles de un formulario.
 * @param {HTMLFormElement} form - El formulario del que se obtendrán los campos requeridos.
 * @returns {HTMLElement[]} - Lista de campos requeridos visibles.
 */
function getVisibleRequiredInputs(form) {
  // Selecciona todos los campos requeridos
  const requiredInputs = Array.from(
    form.querySelectorAll(
      '.form-control.required, .required select, .required textarea, .required input[type="checkbox"]'
    )
  );

  // Selecciona todos los elementos que deberían ser ignorados
  const ignoredInputs = new Set(
    Array.from(
      form.querySelectorAll(
        `[disabled],
        .form-control:disabled,
        .disabled,
        .readonly,
        [readonly],
        .display-none,
        .d-none,
        .hidden,
        .hide,
        [style*="display: none"]`
      )
    )
  );

  // Filtra los campos requeridos para excluir los que están en la lista de ignorados
  return requiredInputs.filter((input) => !isParentIgnored(input, ignoredInputs));
}

/**
 * Verifica si un elemento o alguno de sus padres está en la lista de ignorados.
 * @param {HTMLElement} input - El campo a verificar.
 * @param {Set<HTMLElement>} ignoredInputs - Conjunto de elementos a ignorar.
 * @returns {boolean} - Retorna true si el elemento o su padre debe ser ignorado.
 */
function isParentIgnored(input, ignoredInputs) {
  let currentElement = input;
  while (currentElement) {
    if (ignoredInputs.has(currentElement)) {
      return true;
    }
    currentElement = currentElement.parentElement;
  }
  return false;
}

function getActiveForm() {
  const openModal = document.querySelector('.modal.show');
  const openAside = document.querySelector('.aside.open');
  if (openModal) return openModal.querySelector('form');
  if (openAside) return openAside.querySelector('form');
  return null;
}

/**
 * Maneja el evento de envío del formulario, validando los campos requeridos.
 * @param {Event} event - El evento de envío.
 * @param {HTMLElement[]} requiredInputs - Lista de campos requeridos visibles.
 */
function handleFormSubmit(event, requiredInputs) {
  let hasErrors = false;
  let firstErrorElement = null;

  // Verifica si hay un modal abierto
  const activeForm = getActiveForm() || event.target;

  // Obtén los campos requeridos del formulario activo
  const activeInputs = requiredInputs.filter((input) => activeForm.contains(input));

  activeInputs.forEach((input) => {
    if (!validateInput(input)) {
      hasErrors = true;
      if (!firstErrorElement) {
        // Encuentra el primer input con error
        const formGroup = input.closest('.form-group');
        if (formGroup && formGroup.classList.contains('has-error')) {
          firstErrorElement = formGroup.querySelector('input, select, textarea, input[type="checkbox"]');
        }
      }
    }
  });

  if (hasErrors) {
    event.preventDefault();
    event.stopPropagation();

    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      firstErrorElement.focus({ preventScroll: true });
    }
  }
}

/**
 * Añade eventos de validación en tiempo real a los campos requeridos.
 * @param {HTMLElement[]} requiredInputs - Lista de campos requeridos visibles.
 */
function addRealTimeValidation(requiredInputs) {
  previousRequiredInputs.forEach(input => {
    if (activeListeners.has(input)) {
      const listeners = activeListeners.get(input);
      listeners.forEach(({ eventType, listener }) => {
        input.removeEventListener(eventType, listener);
      });

      activeListeners.delete(input);
    }
  });

  requiredInputs.forEach((input) => {
    const listeners = [];

    ['input', 'change', 'blur'].forEach((eventType) => {
      const listener = () => validateInput(input);

      input.addEventListener(eventType, listener);
      listeners.push({ eventType, listener });
    });

    activeListeners.set(input, listeners);
  });

  previousRequiredInputs = requiredInputs;
}

/**
 * Valida un campo individual y muestra mensajes de error si es necesario.
 * @param {HTMLElement} input - El campo a validar.
 * @returns {boolean} - Retorna true si el campo es válido, de lo contrario false.
 */
function validateInput(input) {
  const formGroup = input.closest('.form-group');
  const value = input.type === 'checkbox' ? input.checked : input.value.trim();
  let hasError = false;

  /* eslint-disable-next-line no-negated-condition */
  if (!value) {
    hasError = true;
    showError(formGroup, 'Campo obligatorio');
  }
  else {
    clearError(formGroup);
  }

  return !hasError;
}

/**
 * Muestra un mensaje de error en un grupo de formulario.
 * @param {HTMLElement} formGroup - El grupo de formulario donde se mostrará el error.
 * @param {string} message - El mensaje de error.
 */
function showError(formGroup, message) {
  formGroup.classList.add('has-error');
  let helpBlock = formGroup.querySelector('.help-block');
  if (!helpBlock) {
    helpBlock = document.createElement('span');
    helpBlock.classList.add('help-block');
    helpBlock.textContent = message;
    formGroup.appendChild(helpBlock);
  }
}

/**
 * Elimina el estado de error de un grupo de formulario.
 * @param {HTMLElement} formGroup - El grupo de formulario del que se eliminará el error.
 */
function clearError(formGroup) {
  formGroup.classList.remove('has-error');
  const helpBlock = formGroup.querySelector('.help-block');
  if (helpBlock) {
    formGroup.removeChild(helpBlock);
  }
}

// Inicializa onmount
document.addEventListener('DOMContentLoaded', () => onmount());
document.addEventListener('turbo:load', () => onmount());
document.addEventListener('turbolinks:load', () => onmount());
