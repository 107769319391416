/**
 * @file Script de deep linking que redirecciona navegadores móviles a la aplicación nativa de Buk
 * Solo se activa una vez por sesión del navegador
 */
import onmount from 'onmount';

onmount('#buk-deep-linking-original-url', function () {
  const hasAttemptedRedirect = sessionStorage.getItem('buk_app_deep_link_attempted');
  if (hasAttemptedRedirect) {
    return;
  }

  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isInAppWebView = window.ReactNativeWebView !== undefined;

  if (!isMobileDevice || isInAppWebView) {
    return;
  }

  sessionStorage.setItem('buk_app_deep_link_attempted', 'true');

  let targetUrl = window.location.href;

  const originalUrl = this.getAttribute('content');
  if (window.location.pathname.includes('/users/sign_in') && originalUrl && originalUrl.length > 0) {
    targetUrl = originalUrl.startsWith('/') ?
      window.location.origin + originalUrl : originalUrl;
  }

  // Convierte la URL web a deep link
  const deepLink = targetUrl.replace(/^https?:\/\//, 'buk://');

  setTimeout(function () {
    window.location.href = deepLink;
  }, 500);
});
