/** @file comportamiento formulario de edicion de haberes **/

import onmount, { $ } from 'onmount';

async function displayDeclararDt() {
  const declararDtBono = document.getElementById('declarar-dt-bono');
  const bonoRebaja = $('#bono_rebaja').val();
  const bonoTipoRemuneracion = $('#bono_tipo_remuneracion').val();
  const bonoTermino = $('#checkbox_termino').is(':checked');

  if (
    (bonoRebaja === 'Fijo' || bonoRebaja === 'Formulado') &&
    (bonoTipoRemuneracion === 'remuneracion_fija') &&
    !bonoTermino
  ) {
    declararDtBono.className = 'checkbox-inline ';
  }
  else {
    $('#bono_declarar_dt').prop('checked', false);
    declararDtBono.className = 'checkbox-inline display-none';
  }
}

onmount('#declarar-dt-bono', async function () {
  const bonoRebaja = $('#bono_rebaja');
  const bonoTipoRemuneracion = $('#bono_tipo_remuneracion');
  const bonoTermino = $('#checkbox_termino');

  bonoRebaja?.on('select2:select', displayDeclararDt);
  bonoTipoRemuneracion?.on('select2:select', displayDeclararDt);
  bonoTermino?.change(displayDeclararDt);
});
